<template>
	<div class="ns-login-wrap" v-loading = "loading" >
	  <div class="login-con  flex align-cen">
       <div class="login-form" v-if="flag">
		 <!-- <el-select v-model="logintype"  placeholder="请选择">
				<el-option
				v-for="item in options"
				:key="item.value"
				:label="item.label"
				:value="item.value">
				</el-option>
			</el-select> -->
			<div>管理员登录</div>
		  <el-input
			placeholder="请输入账号"
			v-model="userPhone"
			clearable>
			</el-input>
			<el-input placeholder="请输入密码" v-model="password" show-password clearable></el-input>
			<div class="forgetpsd flex">
				<div  @click="centerDialogVisible = true">忘记密码</div>
			</div>
			<div class="loginbtn" @click="login">登录</div>
	   </div>
	   <!-- 修改密码 -->
	   <div class="setPsd login-form" v-if="!flag">
		   <div>
			  修改密码
		  </div>
		  	  <el-input
			placeholder="请输入账号"
			v-model="olduserPhone"
			clearable>
			</el-input>
			<el-input placeholder="请输入原密码" v-model="oldpassword" show-password clearable></el-input>
			<el-input placeholder="请输入新密码" v-model="newpassword" show-password clearable></el-input>
			<div class="forgetpsd flex">
				<div style="margin-left:auto" @click="flag = true">登录</div>
			</div>
			<div class="loginbtn loginbtn1">确定</div>
	   </div>
	  </div>
	  <div class="footertext">
		  <div>Copyright © 2021 北京和隆优化科技股份有限公司 </div>
		  <div>京ICP备13020522号</div>
		  <div>技术支持：北京和隆技术研究院</div>
	  </div>
	  	  <el-dialog
				title="提示"
				:visible.sync="centerDialogVisible"
				custom-class="forgetPop"
				center>
				<div class="PopHt">
						<div class="param-header-icon" @click="centerDialogVisible = false">
					<img
						src="~@/assets/images/close.png"
						style="width: 100%; height: auto"
						/>
					</div>
					<div class="PopHt-tit">温馨提示</div>
				<div class="PopHt-con flex align-cen justify-content-cen">
				<div class="">
					<div>忘记密码请联系管理员</div>
					<div>联系方式：13080927653</div> 
							 </div>
						 </div>
					 </div>
</el-dialog>
	</div>
</template>

<script>
// , {
//           value: 1,
//           label: '管理员登录'
//         }
import { loginAdmin} from "@/api/auth/index" //登录
export default {
	name: 'login',
	data: () => {
    return {
		 options: [{
          value: 0,
          label: '管理员登录'
        }],
        logintype: 0,
		labelPosition: 'right',
		userPhone:'',
		password:'',
		code:'',
		dialogVisible:false,
		flag:true,
		olduserPhone:'',
		oldpassword:'',
		newpassword:'',
		loading:false,
		centerDialogVisible:false
	}
	},
	methods:{
		login(){
			if(!this.userPhone){
				return this.$message.error('请输入账号');
			}
			if(!this.password){
				return this.$message.error('请输入密码');
			}
			this.loading = true
			 loginAdmin({
				account:this.userPhone,
				password:this.password
			 })
               .then(res => { 
				   this.loading = false
					   if(res.info.code == 500){
						return this.$message.error(res.info.msg);
					   }else{
						 
						localStorage.setItem("UserInfo", JSON.stringify(res.msg))
						localStorage.setItem("authInfo", JSON.stringify(res.auth))
						this.$router.push({ path: '/userAdmin' });
					   }
                    })
                    .catch(err => {
						this.loading = false
                        return this.$message.error("登录失败");
           })
		},
	},
	// mixins: [login]
};
</script>
<style lang="scss" scoped>
.PopHt{
color: #fff;
.PopHt-tit{
	text-align: center;
	line-height: 0.8vh;
	font-size: 1vh;
	height: 5vh;
	line-height: 5vh;
}
.PopHt-con{
	height: 25vh;
	div{
		div{
			padding-bottom: 2vh;
		}
	}
}
}
    .param-header-icon {
      width: 2vw;
      height: 2vh;
      position: absolute;
      right: 1vw;
      top: 1vh;
      cursor: pointer;
    }
.ns-login-wrap {
	width: 100%;
	height: 100%;

	.login-con{
		width: 64vw;
		height: 68vh;
		margin: 0 auto;
		margin-top: 11vh;
		background: url('~@/assets/images/loginbanner.png') no-repeat 0 0;
   	 	background-size: 100% 100%;
			position: relative;
		.login-form{
			width: 19vw;
			height: 40vh;
			margin-left: auto;
			margin-top: 16vh;
			padding-right: 4vw;
			color: #fff;
			font-size: 1.3vw;
			font-family: MicrosoftYaHeiLight;
			padding-top: 40px;
			.forgetpsd{
				div{
					font-size: 14px;
					cursor: pointer;
				}
			}
			.loginbtn{
				width: 10vw;
				height: 4vh;
				background-color: #473eff;
				box-shadow: 0px 0px 12px 0px #4a93f6;
				border-radius: 20px;
				text-align: center;
				font-size: 1vw;
				line-height: 4vh;
				font-family: FZLTHK--GBK1-0;
				cursor: pointer;
				margin: 0 auto;
				margin-top: 10vh;
			}
			.loginbtn1{
				margin-top: 4vh;
			}
		}	
	}
			.footertext{
			width: 100%;
			text-align: center;
			color: rgba(255,255,255,.3);
			font-size: 14px;
			font-family: MicrosoftYaHei;
			position: absolute;
			bottom: 4vh;
			line-height: 24px;
		}
}

::v-deep {
	.el-dialog--center .el-dialog__body{
		padding: 0;
	}
	.el-dialog__header{display: none;}
.login-form{
	.el-input__inner{
		background: none;
		border:none;
		border-bottom: 1px solid rgba(255,255,255,.2);
		border-radius: 0;
		padding-right: 0;
		padding-left: 2px;
		color: #fff;
		margin-bottom: 2vh;
	}
	.el-select{
		width: 100%;
	}
	.el-input__suffix {
		top: -8px;
	}
}	
}
</style>

<style lang="scss">
.ns-login-form {
	.el-form-item__error {
		/* 错误提示信息 */
		padding-left: 57px;
	}

	.el-tabs__active-bar,
	.el-tabs__nav-wrap::after {
		/* 清除tab标签底部横线 */
		height: 0;
	}

	/* 立即注册 */
	.el-form-item__content {
		line-height: 20px;
	}
}
</style>
